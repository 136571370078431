<template>
  <div class="brands-wrap">
    <div class="w-full max-w-rifyo mx-auto pt-14 md:pt-24 pb-20">
      <div class="max-xl:px-7">
        <div class="relative mt-20 mb-10">
          <h1 class="page-title__brands">List of Brands</h1>
        </div>
        <div class="loading mx-auto flex justify-center p-12" v-if="isLoading">
          <Loading />
        </div>
        <div class="grid grid-cols-3 gap-4" v-if="!isLoading">
          <div 
            v-for="brand in brandsList"
            :key="brand.id"
            class="col-span-3 md:col-span-1 border border-[#eae3d9] box-border"
          >
            <div class="brand-card__header mb-6 md:mb-8">
              <img
                :src="brand.thumbnail"
                class="w-full object-cover hidden md:block"
              />
              <img
                :src="brand.thumbnail_mobile"
                class="w-full object-cover md:hidden"
              />
            </div>
            <div class="px-6 pb-7 md:pb-9">
              <img
                :src="brand.logo"
                class="w-2/3 h-auto mb-8"
              />
              <div class="brand-card__thumb mb-5">
                {{ brand.description | truncate(160, '...') }}
              </div>
              <router-link :to="`/brands-detail/${brand.slug}`">
                <div class="flex flex-wrap items-center -mr-4 cursor-pointer">
                  <p class="brand-card__link pr-4">Learn More</p>
                  <img src="/playground_assets/arrow-right.svg" alt="arrow">
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/footer';
  import Api from '../api';
  import XClose from '../components/x-close';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'Brands',
    props: {},
    data() {
      return {
        brandsList: [],
        msg_err: null,
        isError: false,
        isLoading: false,
      };
    },
    created() {
      this.getbrands();
    },
    methods: {
      closeMsg() {
        this.msg_err = null;
        this.isError = false;
      },
      async getbrands() {
        try {
          this.isLoading = true;
          const response = await this.$http.get(Api.brandsListUrl);
          this.isLoading = false;
          this.brandsList = response.data.data;
        } catch (error) {
          this.isLoading = false;
          this.msg_err = error.message;
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 3000);
        }
      },
    },
    components: {
      Footer,
      XClose,
      Loading,
    },
  };
</script>

<style scoped>
.page-title__brands {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
}

.brand-card__link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.24em;
  text-transform: capitalize;
}

.brand-card__thumb {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #1F252C;
}

@media (max-width:767px) {
  .page-title__brands {
    font-size: 36px;
    line-height: 46px;
  }
}
</style>
